import styled, { css, RuleSet } from 'styled-components';
import { EElementSize } from '../../types';
import { EState, IInputWrapperStyledProps, TSize } from './input-wrapper.types';

const StateMap: Record<EState, RuleSet> = {
  [EState.NONE]: css``,
  [EState.ERROR]: css`
    &, &:not([disabled]):hover {
      border-color: ${({ theme }) => theme.colors.red[50]};
    }

    .status-icon path {
      fill: ${({ theme }) => theme.colors.red[50]};
    }
  `,
  [EState.WARNING]: css`
    &, &:not([disabled]):hover {
      border-color: ${({ theme }) => theme.colors.yellow[40]};
    }

    .status-icon path {
      fill: ${({ theme }) => theme.colors.yellow[40]};
    }
  `
};

const SizeMap: Record<TSize, RuleSet> = {
  [EElementSize.XL]: css`
    padding: 0 24px;
    height: 64px;

    input, select {
      padding: 20px 0;
    }
  `,
  [EElementSize.L]: css`
    padding: 0 16px;
    height: 48px;

    input, select {
      padding: 14px 0;
    }
  `,
  [EElementSize.M]: css`
    padding: 0 12px;
    height: 40px;

    input, select {
      padding: 10px 0;
    }
  `,
  [EElementSize.S]: css`
    padding: 0 8px;
    height: 32px;

    input, select {
      padding: 6px 0;
    }
  `,
  textarea: css`
    padding: 0;
    overflow: hidden;

    textarea {
      padding: 10px 12px;
      width: 100% !important;
      min-height: 30px;
      box-sizing: border-box;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
    }
`,
};

export const InputWrapperStyled = styled.div<IInputWrapperStyledProps>`
  background-color: ${({ theme }) => theme.colors.gray[3]};
  border: 1px solid ${({ theme }) => theme.colors.gray[10]};
  color: ${({ theme }) => theme.colors.gray[90]};
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  width: ${({ $width }) => $width};
  cursor: ${({ $cursor }) => $cursor || 'text'};

  .inner-content {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    input, select, textarea {
      width: 100%;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      box-sizing: border-box;
    }
  }

  ${({ $isStatic }) => !$isStatic && css`
    &:not([disabled]):hover {
      border: 1px solid ${({ theme }) => theme.colors.gray[40]};

      input::placeholder, select::placeholder, textarea::placeholder, .placeholder {
        color: ${({ theme }) => theme.colors.gray[50]};
      }

      .input-wrapper-icon {
        svg {
          path {
            fill: ${({ theme }) => theme.colors.gray[50]};
          }
        }
      }

      .input-wrapper-action {
        svg {
          path {
            fill: ${({ theme }) => theme.colors.gray[40]};
          }
        }
      }
    }

    &:focus-within {
      border: 1px solid ${({ theme }) => theme.colors.gray[90]};
    }
  `}

  ${({ $size }) => SizeMap[$size]}

  .input-wrapper-action,
  .input-wrapper-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .input-wrapper-action {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.gray[30]};
      }
    }

    &-clear {
      cursor: pointer;
    }

    &:active {
      path {
        fill: ${({ theme }) => theme.colors.gray[50]};
      }
    }
  }

  .input-wrapper-icon {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.gray[30]};
      }
    }
  }

  input, select, textarea {
    border: none;
    background-color: transparent;
    color: inherit;
    flex-grow: 1;
    width: auto;
    -webkit-appearance: none;
    cursor: inherit;

    &:focus {
      outline: none;
    }
  }

  input::placeholder, select::placeholder, textarea::placeholder, .placeholder {
    color: ${({ theme }) => theme.colors.gray[30]};
  }

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.gray[20]};
    border: 1px solid ${({ theme }) => theme.colors.gray[3]};
    cursor: not-allowed;

    .input-wrapper-action {
      &-clear {
        cursor: inherit;
      }

      svg {
        path {
          fill: ${({ theme }) => theme.colors.gray[20]};
        }
      }
    }
  `}

  ${({ $state }) => StateMap[$state]}
`;

export const InputWrapperStateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
