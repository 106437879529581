import styled, { RuleSet, css } from 'styled-components';
import { IStyledChipProps } from './chip.types';

const VariantsMap: Record<number, RuleSet<IStyledChipProps>> = {
  [Number(true)]: css<IStyledChipProps>`
    background-color: ${({ theme }) => theme.colors.gray[5]};
    border: 1px solid ${({ theme }) => theme.colors.gray[5]};
    padding: 4px 12px 4px 6px;

    .icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.gray[0]};
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg path {
        stroke: ${({ theme }) => theme.colors.gray[30]};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[10]};

      .icon svg path {
        stroke: ${({ theme }) => theme.colors.gray[50]};
      }
    }
  `,
  [Number(false)]: css<IStyledChipProps>`
    background-color: ${({ theme }) => theme.colors.gray[0]};
    border: 1px solid ${({ theme }) => theme.colors.gray[10]};
    padding: 4px 12px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[5]};
      border: 1px solid ${({ theme }) => theme.colors.gray[10]};
    }
  `,
};

export const ChipStyled = styled.span<IStyledChipProps>`
  display: inline-flex;
  align-items: center;
  height: 28px;
  gap: 4px;
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colors.gray[50]};

  ${({ $hasIcon }) => VariantsMap[Number($hasIcon)]}

  &:active ${({ $checked }) => $checked && ', &'} {
    background-color: ${({ theme }) => theme.colors.blue[5]};
    border: 1px solid ${({ theme }) => theme.colors.blue[10]};
    color: ${({ theme }) => theme.colors.gray[90]};

    .icon {
      background-color: ${({ theme }) => theme.colors.blue[50]};

      svg path {
        stroke: ${({ theme }) => theme.colors.gray[0]};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.blue[10]};
      border: 1px solid ${({ theme }) => theme.colors.blue[10]};

      .icon svg path {
        stroke: ${({ theme }) => theme.colors.gray[0]};
      }
    }
  }
`;
