import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatchInputEvent } from '../../lib';
import { EElementSize } from '../../types';
import { InputWrapper } from '../input-wrapper';
import { IFieldTextProps } from './field-text.types';

export default function mergeRefs<T = unknown>(
  ...refs: React.ForwardedRef<T>[]
): React.RefCallback<T> {
  return (node: T) => {
    for (const ref of refs) {
      if (ref) {
        if (typeof ref === 'function') ref(node);
        if ('current' in ref) ref.current = node;
      }
    }
  };
}

export const FieldText = forwardRef<HTMLInputElement, IFieldTextProps>(
  (
    {
      width,
      state,
      $size = EElementSize.M,
      isClearable = true,
      actionButtons,
      value,
      icon,
      disabled,
      autoFocus,
      readOnly,
      placeholder,
      onClear = () => {},
      cursor,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const showClearAction = useMemo(
      () => isClearable && !!value,
      [isClearable, value],
    );

    const dispatchInputEvent = useDispatchInputEvent(inputRef);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus({ preventScroll: true });
      }
    }, [autoFocus]);

    const clearHandler = useCallback(() => {
      dispatchInputEvent('');
      inputRef.current?.focus();
      onClear();
    }, [dispatchInputEvent, inputRef, onClear]);

    return (
      <InputWrapper
        $size={$size}
        state={state}
        disabled={disabled}
        icon={icon}
        actionButtons={actionButtons}
        width={width}
        isClearable={showClearAction}
        onClear={clearHandler}
        cursor={cursor}
      >
        {readOnly ? (
          value ? (
            <>{value}</>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )
        ) : (
          <input
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            {...props}
            ref={mergeRefs(ref, inputRef)}
          />
        )}
      </InputWrapper>
    );
  },
);
