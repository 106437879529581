import { EElementSize } from '../../types';

export enum EState {
  ERROR = 'error'
}

export enum EButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GHOST_BLUE = 'ghost-blue',
  GHOST_GRAY = 'ghost-gray',
}

export type EButtonSize = Exclude<EElementSize, EElementSize.L | EElementSize.XL>;

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  prependIcon?: React.ReactElement;
  appendIcon?: React.ReactElement;
  isLoading?: boolean;
  state?: EState;
  $size?: EButtonSize;
  $type?: EButtonType;
}

export interface IStyledButtonProps {
  $state?: EState;
  $size: EButtonSize;
  $type: EButtonType;
}
