import { FC } from 'react';
import { EDialogSize } from '../../lib/dialog';
import { EElementSize } from '../../types';
import { Button, EButtonType } from '../button';
import { Close24Icon } from '../icons';
import { DialogStyled } from './dialog.styled';
import { IDialogProps } from './dialog.types';

export const Dialog: FC<IDialogProps> = ({
  title,
  description,
  content,
  closable,
  size = EDialogSize.S,
  hasFooter = true,
  onClose,
  onConfirm,
}) => {
  return (
    <DialogStyled $size={size}>
      <div className="dialog-shadow" onClick={onClose}></div>
      <div className="dialog-wrapper">
        {(title || description || closable) && (
          <div className="dialog-header">
            <div className="dialog-text">
              {!!title && <div className="dialog-title">{title}</div>}
              {!!description && (
                <div className="dialog-description">{description}</div>
              )}
            </div>
            {closable && (
              <Button
                className="dialog-close"
                onClick={onClose}
                $size={EElementSize.M}
                $type={EButtonType.GHOST_GRAY}
                prependIcon={<Close24Icon />}
              />
            )}
          </div>
        )}
        {!!content && <div className="dialog-content">{content}</div>}
        {size === EDialogSize.S && hasFooter && (
          <div className="dialog-actions">
            <Button onClick={onConfirm}>Подтвердить</Button>
            <Button onClick={onClose} $type={EButtonType.SECONDARY}>
              Отменить
            </Button>
          </div>
        )}
      </div>
    </DialogStyled>
  );
};
