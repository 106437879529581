export enum EDialogSize {
  S = 'small',
  L = 'large'
}

export interface IDialogOptions {
  title?: string;
  description?: string;
  content?: React.ReactElement;
  hasFooter?: boolean;
  size?: EDialogSize;
  closable?: boolean;
}

export interface IDialogContext {
  closeDialog: () => void;
  openDialog: (payload: IDialogOptions) => Promise<void>;
  isOpen: boolean;
}
