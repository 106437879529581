import { useTranslation } from 'react-i18next';
import { UserGroupEnum } from 'videoservice-openapi-react';
import { useFeature } from 'shared/lib';
import { useHasRole } from 'shared/lib/role';
import { INavigationItem } from './use-group-navigation.types';

export const useGroupNavigation = () => {
  const { t } = useTranslation();
  const { hasRole } = useHasRole();
  const { hasFeature } = useFeature();

  const isAllowMonitoring = hasFeature('ALLOW_MONITORING');

  const navigationList: INavigationItem[] = [
    {
      path: '/broadcasts',
      text: t('broadcasts'),
      isVisible: hasRole(UserGroupEnum.ADMIN, UserGroupEnum.CUSTOMER, UserGroupEnum.OPERATOR),
    },
    {
      path: '/restrictions',
      text: t('geozone settings'),
      isVisible: hasRole(UserGroupEnum.CUSTOMER),
    },
    {
      path: '/providers',
      text: t('providers'),
      isVisible: hasRole(UserGroupEnum.CUSTOMER),
    },
    {
      path: '/secrets',
      text: t('secrets'),
      isVisible: hasRole(UserGroupEnum.CUSTOMER),
    },
    {
      path: '/monitoring',
      text: t('monitoring'),
      isVisible: !!isAllowMonitoring && hasRole(UserGroupEnum.ADMIN, UserGroupEnum.OPERATOR, UserGroupEnum.CUSTOMER),
    },
    {
      path: '/synonyms',
      text: t('synonyms'),
      isVisible: hasRole(UserGroupEnum.ADMIN, UserGroupEnum.OPERATOR),
    },
    {
      path: '/admin/customers',
      text: 'Кастомеры',
      isVisible: hasRole(UserGroupEnum.ADMIN),
    },
  ];

  return navigationList.filter(el => el.isVisible);
};
